import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { slideImgOne,
    slideImgTwo,
    slideImgThree,
    featureImgOne,
    featureImgTwo,
    featureImgThree,
    serviceImgOne,
    serviceImgThree,
    serviceImgFour,
    serviceImgSix,
    blogImgOne,
    blogImgTwo,
    blogImgThree } 
from '../../assist/index';
import './Body.css';
import pageTransition from '../../variants/pageTransition'

const featureItems = [
    {
        img: featureImgOne,
        title: "A Perfect Pair",
        description: "We've got the perfect plant for your perfect pot!",
        link: "/plant-directory"
    },
    {
        img: featureImgTwo,
        title: "Thoughtful Gifting",
        description: "Event and corporate gifts everyone will love.",
        link: "/plant-directory"
    },
    {
        img: featureImgThree,
        title: "Pots for Everyone",
        description: "Explore the entire collection!",
        link: "/plant-directory"
    }
];

const shopItems = [
    {
        img: serviceImgOne,
        title: "Plant Installation",
        link: "/plant-class"
    },
    {
        img: serviceImgThree,
        title: "Plant Design",
        link: "/plant-install"
    },
    {
        img: serviceImgFour,
        title: "Plant Classes",
        link: "/plant-design"
    },
    {
        img: serviceImgSix,
        title: "Plant Health",
        link: "/plant-health"
    }
];

const blogPosts = [
    {
        img: blogImgOne,
        title: "Wellness and Your Indoor Air Quality",
        date: "May 17, 2021",
        link: "/individual-blog"
    },
    {
        img: blogImgTwo,
        title: "Simple Spring Projects",
        date: "April 05, 2021",
        link: "/individual-blog"
    },
    {
        img: blogImgThree,
        title: "Easy as Z to Z",
        date: "February 28, 2021",
        link: "/individual-blog"
    }
];

const Body = () => {

        const [slideIndex, setSlideIndex] = useState(0);
        const backgroundImages = [slideImgOne, slideImgTwo, slideImgThree];
        const maxIndex = backgroundImages.length - 1;
        let intervalId;
    
        const changeBackgroundImage = useCallback(() => {
            const heroSection = document.querySelector('.hero');
            heroSection.style.backgroundImage = `url(${backgroundImages[slideIndex]})`;
        }, [slideIndex, backgroundImages]);
    
        const startAutoSlide = () => {
            intervalId = setInterval(() => {
                setSlideIndex(currentIndex => (currentIndex + 1) % (maxIndex + 1));
            }, 5000);
        };
    
        const resetAutoSlide = () => {
            clearInterval(intervalId);
            startAutoSlide();
        };
    
        useEffect(() => {
            startAutoSlide();
            return () => clearInterval(intervalId);
        }, [maxIndex]);
    
        const updateDots = useCallback(() => {
            const dots = document.querySelectorAll('.dot');
            dots.forEach((dot, index) => {
                if (index === slideIndex) {
                    dot.classList.add('active');
                } else {
                    dot.classList.remove('active');
                }
            });
        }, [slideIndex]);
    
        useEffect(() => {
            changeBackgroundImage();
            updateDots();
            const dots = document.querySelectorAll('.dot');
    
            const handleClick = (index) => () => {
                setSlideIndex(index);
                resetAutoSlide(); // Reset the auto slide timer when a dot is clicked
            };
            dots.forEach((dot, index) => {
                dot.addEventListener('click', handleClick(index));
            });
            return () => {
                dots.forEach((dot, index) => {
                    dot.removeEventListener('click', handleClick(index));
                });
            };
        }, [changeBackgroundImage, updateDots]);

    const maxTitleLength = 35; 
    const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;
    

    return (
        <div>
            <Helmet>
                <title>CICELY</title>
                <meta name="description" content="Discover the charm of Cicely with our range of products and unique boutique items. Explore our story and shop with us today." />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <section className="hero" style={{ backgroundImage: `url(${backgroundImages[slideIndex]})` }}>
                <div className="hero-content">
                    <h1>Hello Spring</h1>
                    <button><Link to="/plant-directory" reloadDocument>Shop Sale</Link></button>
                    <div className="slideshow-controls">
                        {backgroundImages.map((_, idx) => (
                            <span key={idx} className={`dot ${idx === slideIndex ? 'active' : ''}`} aria-label={`Background slide ${idx + 1}`}></span>
                        ))}
                    </div>
                </div>
            </section>        
            <section id="features">
                <div className="container">
                    {featureItems.map((item, index) => (
                        <div key={index} className="feature">
                            <div className="feature-image">
                                <img src={item.img} alt={item.title}/>
                            </div>
                            <div className="feature-text">
                                <h2>{item.title}</h2>
                                <p>{item.description}</p>
                                <button><Link to={item.link} reloadDocument>Shop</Link></button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className="services">
                <div className="container">
                    <h2>Services</h2>
                    <div className="service-items">
                        {shopItems.map((item, index) => (
                            <div key={index} className="item">
                                <img src={item.img} alt={item.title}/>
                                <div className="overlay">
                                    <button><Link to={item.link} reloadDocument>{item.title}</Link></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>             
            <section id="promotions">
                <div> 
                    <div className="promo-section">  
                        <div className="promo-content">
                            <h2>The Cicely Promise to You</h2>
                            <h3>Quality you can trust.</h3>
                            <button><Link to="/" reloadDocument>Read More</Link></button>
                        </div>
                    </div>
                </div>
            </section>
            <section id="blog">
                <div className="container">
                    <h2>From The Potted Post</h2>
                    <div className="blog-header">
                        <button><Link to="/blog" reloadDocument>View All</Link></button>
                    </div>
                    <div className="blog-posts">
                        {blogPosts.map((post, index) => (
                            <div key={index} className="post">
                                <img src={post.img} alt={post.title}/>
                                <div className="content-overlay">
                                    <p>{post.date}</p>
                                    <h2>{textLimit(post.title)}</h2>
                                    <button><Link to={post.link} reloadDocument>Read More</Link></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section id="care-tips">
                <div>
                    <div className='care-section'>
                        <div className="care-content">
                            <h2>Easy Care Tips for Your Plants</h2>
                            <h3>We're here to help.</h3>
                            <button><Link to="/" reloadDocument>Read Tips</Link></button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pickup-info">
                <div className="pickup-info-overlay">
                    <h2>View Pickup and Delivery Options</h2>
                    <p>M - F: 9:00am - 6:00pm</p>
                    <Link to="/contact" reloadDocument><button>More Info</button></Link>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.456324194087!2d-79.39477718450073!3d43.66872927912124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34b0fcaa03b7%3A0xcc2b1658740928a2!2sToronto!5e0!3m2!1sen!2sca!4v1622484070764!5m2!1sen!2sca"
                    width="600" height="450" allowFullScreen="" loading="lazy" aria-label="Location map" title="contact-map">
                </iframe>
            </section> 
        </div>
    );
}

export default pageTransition(Body);