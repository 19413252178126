import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './AllServices.css';
import {
  serviceImgOne,
  serviceImgTwo,
  serviceImgThree,
  serviceImgFour,
  serviceImgFive,
  serviceImgSix
} from '../../assist/index';
import pageTransition from '../../variants/pageTransition'

const serviceInfo = [
  {
    img: serviceImgOne,
    title: "Plant Installation",
    description: "Ensure your plants are perfectly placed and securely planted with our professional installation services, tailored to any scale of project.",
    path: "/plant-install"
  },
  {
    img: serviceImgTwo,
    title: "Plant Maintenance",
    description: "Our plant maintenance services cover everything from watering and pruning to fertilizing and pest control, keeping your plants healthy and vibrant.",
    path: "/plant-maint"
  },
  {
    img: serviceImgThree,
    title: "Custom Planter Design",
    description: "Create stunning, personalized plant arrangements with our custom planter design services, designed to enhance any personal or professional space.",
    path: "/plant-design"
  },
  {
    img: serviceImgFour,
    title: "Plant Classes",
    description: "Join our workshops to learn about plant care, gardening techniques, and fun DIY projects, suitable for all skill levels.",
    path: "/plant-class"
  },
  {
    img: serviceImgFive,
    title: "Seasonal Planting",
    description: "Keep your plant displays fresh and seasonal with our planting services, updating your space with the perfect plants for each season.",
    path: "/seasonal-planting"
  },
  {
    img: serviceImgSix,
    title: "Plant Health Diagnosis",
    description: "Get expert advice on plant health with our diagnosis services, identifying and treating issues from pests to nutrient deficiencies, either on-site or virtually.",
    path: "/plant-health"
  },
]
const AllServices = () => {
  return (
    <div>
      <Helmet>
        <title>CICELY | Services</title>
        <meta name="description" content="All Services" />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div class="allservice-hero-image">
            <div class="allservice-hero">
                <h1>
                    Our services
                </h1>
            </div>
        </div>
      </div>
      <div className="container">
        <div className="services-grid">
        {serviceInfo.map(service => (
          <div className="service-card" key={service.title}>
            <img src={service.img} alt={service.title} />
            <h2>{service.title}</h2>
            <p>{service.description}</p>
            <Link to={service.path} reloadDocument>
              <button>Read More</button>
            </Link>
          </div>
        ))}
        </div>
      </div>
      <hr/>
    </div>
  )
}

export default pageTransition(AllServices);