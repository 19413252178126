import React, { useState } from 'react';
import './Product.css';
import { Helmet } from 'react-helmet';
import { plantItemOne, 
        plantItemTwo, 
        plantItemThree, 
        plantItemFour,
        productImgOne,
        productImgTwo,
        productImgThree } from '../../assist/index';

const products = [
{ 
    src: plantItemOne, 
    name: 'Assorted Small Succulents', 
    price: '$10',
    ratings: "★★★★★",
    reviews: "#reviews"
},
{
    src: plantItemTwo, 
    name: 'Small Aloe Vera', 
    price: '$10',
    ratings: "★★★★★",
    reviews: "#reviews" 
},
{ 
    src: plantItemThree, 
    name: 'Medium Bonsai', 
    price: '$25',
    ratings: "★★★★★",
    reviews: "#reviews"  
},
{ 
    src: plantItemFour, 
    name: 'Medium Orchid', 
    price: '$30',
    ratings: "★★★",
    reviews: "#reviews"  
}
];

const ProductPage = () => {
    const [mainImage, setMainImage] = useState({
        src: productImgTwo,
        alt: 'Assorted Small Succulents'
      });
    
      const changeImage = (src, alt) => {
        setMainImage({ src, alt });
      };

  return (
    <div>
      <Helmet>
        <title>CICELY | Explore Our Succulent Collection</title>
        <meta name="description" content="Explore our handpicked assortment of small, easy-to-care-for succulents that add a touch of green to any space." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div className="product-display">
        <div className="product-image">
        <img id="main-image" src={mainImage.src} alt={mainImage.alt} />
        <div className="image-subImage">
            <img 
            src={productImgOne}
            alt="subImage1" 
            onClick={() => changeImage(productImgOne, 'subImage1')}
            />
            <img 
            src={productImgTwo}
            alt="subImage2" 
            onClick={() => changeImage(productImgTwo, 'subImage2')}
            />
            <img 
            src={productImgThree} 
            alt="subImage3" 
            onClick={() => changeImage(productImgThree, 'subImage3')}
            />
        </div>
        </div>       
          <div className="product-details">
            <h1>Assorted Small Succulents</h1>
            <h2>$10</h2>
            <p>
              Receive a curated succulent from one of our best seller collection,
              easy to care for and always in season!
            </p>
            <div className="rating">
              <span className="rating-star">★★★★★</span>
              <span># reviews</span>
            </div>
            <div className="button1">
              <button className="add-to-cart">ADD TO CART</button>
            </div>
            <div className="button2">
              <button className="buy-now">BUY NOW</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="product-container">
          <h2>Similar Product</h2>
        </div>
        <div className="shop-grid">
          {products.map(product => (
            <div className="plant-item">
              <img src={product.src} alt={product.name} width="100%" height="80%" />
              <div className="description">
                <div className="info">
                  <span className="name">{product.name}</span>
                </div>
                <span className="price">{product.price}</span>
              </div>
              <div className="meta">
                <span className="ratings">{product.ratings}</span>
                <span className="reviews">{product.reviews}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
