import React, { useState } from 'react';
import './CaseStudies.css';
import { MdDescription, MdOutlineReportProblem, MdAutoFixHigh, MdCloudDone  } from "react-icons/md";
import { Helmet } from 'react-helmet';
import {
    plantDesignOne,
    plantDesignTwo,
    plantDesignThree
} from '../../assist/index';
import { IoMdClose } from "react-icons/io";
import pageTransition from '../../variants/pageTransition';


const designCaseStudies = [
    {
        description: "Developed custom planter designs for residential and commercial spaces to enhance aesthetic appeal and functional use of outdoor and indoor areas.",
        challenge: "Many clients were looking for unique, space-efficient ways to incorporate greenery into their environments, which standard planters could not fulfill due to limitations in design and functionality.",
        solution: "Crafted a range of custom planter designs tailored to the specific dimensions and style preferences of each client. Utilizing durable, eco-friendly materials, these planters were designed by a team of skilled artisans and included features such as self-watering systems and integrated lighting.",
        outcome: "The custom planter designs significantly improved client satisfaction by matching their specific aesthetic and functional needs. The planters not only enhanced the visual appeal of spaces but also encouraged more clients to invest in green solutions, boosting our customer engagement and repeat business."
    }
];



const CustomPlantDesign = () => {

    const [modal, setModal] = useState({ isOpen: false, imgSrc: '' });

    const openModal = (imgSrc) => {
        setModal({ isOpen: true, imgSrc });
    };

    const closeModal = () => {
        setModal({ isOpen: false, imgSrc: '' });
    };

    return (
        <section>
            <Helmet>
                <title>Custom Planter Design</title>
                <meta name="description" content="Explore our bespoke planter designs tailored for both residential and commercial spaces, enhancing aesthetics and functionality with eco-friendly materials." />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <div>
                <div className="case-hero-image">
                    <div className="case-hero">
                        <h1>
                            Custom Planter Design
                        </h1>
                    </div>
                </div>
            </div>
            <div className="case-images">
                <img src={plantDesignOne} alt="Challenge" onClick={() => openModal(plantDesignOne)} />
                <img src={plantDesignTwo} alt="Solution" onClick={() => openModal(plantDesignTwo)} />
                <img src={plantDesignThree} alt="Outcome" onClick={() => openModal(plantDesignThree)} />
            </div>
            {modal.isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}><IoMdClose/></span>
                        <img src={modal.imgSrc} alt="Enlarged view" />
                    </div>
                </div>
            )}
            <div className="card-container">
                {designCaseStudies.map((study, index) => (
                    <div key={index} className="card">
                        <div className="card-item">
                            <MdDescription className="icon" />
                            <h2>Description</h2>
                            <p>{study.description}</p>
                        </div>
                        <div className="card-item">
                            <MdOutlineReportProblem className="icon" />
                            <h2>Challenge</h2>
                            <p>{study.challenge}</p>
                        </div>
                        <div className="card-item">
                            <MdAutoFixHigh className="icon" />
                            <h2>Solution</h2>
                            <p>{study.solution}</p>
                        </div>
                        <div className="card-item">
                            <MdCloudDone className="icon" />
                            <h2>Outcome</h2>
                            <p>{study.outcome}</p>
                        </div>
                    </div>
                ))}
            </div>
            <hr/>
        </section>
    )
}

export default pageTransition(CustomPlantDesign);