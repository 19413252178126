import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MdLabelImportant } from "react-icons/md";
import {
  plantDesignSlideOne,
  plantDesignSlideTwo
} from '../../assist/index';
import './Services.css';
import pageTransition from '../../variants/pageTransition';

const plantDesign = {
  overview: "Transform your space with our bespoke Plant Design services. From initial concept to final implementation, our team of creative landscape designers works closely with you to create stunning, sustainable garden designs that reflect your personal style and enhance your outdoor living areas. Suitable for both residential and commercial properties, our designs are crafted to be as functional as they are beautiful.",
  capabilities: [
    "Custom garden design tailored to client specifications and site conditions.",
    "Integration of aesthetic and functional elements such as walkways, water features, and seating areas.",
    "Selection of appropriate plants based on climate, soil type, and desired maintenance levels.",
    "Use of sustainable design principles to ensure environmental responsibility.",
    "Detailed planning and visualization using modern design software."
  ],
  recognition: "Our innovative designs have garnered attention in 'Modern Landscaping' magazine and earned us the Innovative Design Award at the Annual Landscapers' Conference.",
  detailedServices: [
    {
      title: "Residential Landscape Design",
      description: "Personalized residential landscaping plans that create peaceful, beautiful, and practical spaces for homes."
    },
    {
      title: "Commercial Space Planning",
      description: "Functional and inviting outdoor designs that enhance commercial properties, improving curb appeal and visitor experience."
    },
    {
      title: "Therapeutic Garden Spaces",
      description: "Specialized designs for therapeutic and healing gardens in healthcare settings, focusing on accessibility and the restorative properties of nature."
    }
  ],
};

const PlantDesign = () => {
  return (
    <div>
      <Helmet>
        <title>CICELY | Plant Design Services</title>
        <meta name="description" content="Explore CICELY's bespoke Plant Design services tailored for both residential and commercial spaces." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div className="service-hero-image">
            <div className="service-hero">
                <h1>
                    Plant Design
                </h1>
            </div>
        </div>
      </div>
      <div className="container">
        <div className="service-details-layout">
            <div className="service-text">
            <p>{plantDesign.overview}</p>
            <div>
                <h2>Our Capabilities</h2>
                <ul>
                {plantDesign.capabilities.map((capability, index) => (
                    <li key={index}>{capability}</li>
                ))}
                </ul>
            </div>
            <div>
                <h2>Recognition</h2>
                <p>{plantDesign.recognition}</p>
            </div>
            <div>
                <h2>What We Offer</h2>
                {plantDesign.detailedServices.map((detail, index) => (
                <article key={index}>
                    <h3><MdLabelImportant className='label'/>{detail.title}</h3>
                    <p>{detail.description}</p>
                </article>
                ))}
            </div>
            </div>
            <div className="service-images">
              <img src={plantDesignSlideOne} alt="Therapeutic Garden Spaces" />
              <img src={plantDesignSlideTwo} alt="Commercial Space Planning" />
            </div>
        </div>
      </div>
      <div>
          <div className="service-bottom-hero">
              <div className="service-bottom-hero-content">
              <h2>
                  Envision and Craft 
                  Your Dream Garden!
              </h2> 
                  <h3>Contact us today!</h3>
                  <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
              </div>
          </div>
      </div>
    </div>
  );
}

export default pageTransition(PlantDesign);