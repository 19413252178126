import React, { useState } from 'react';
import './ProductBundle.css';
import { Helmet } from 'react-helmet'; 
import { plantItemOne, 
        plantItemTwo, 
        plantItemThree, 
        plantItemFour } from '../../assist/index';
import { IoMdClose } from "react-icons/io";

const products = [
  { 
    src: plantItemOne, 
    name: 'Assorted Small Succulents', 
    price: '$10',
    ratings: "★★★★★",
    reviews: "#reviews"
  },
  {
    src: plantItemTwo, 
    name: 'Small Aloe Vera', 
    price: '$10',
    ratings: "★★★★★",
    reviews: "#reviews" 
  },
  { 
    src: plantItemThree, 
    name: 'Medium Bonsai', 
    price: '$25',
    ratings: "★★★★★",
    reviews: "#reviews"  
  },
  { 
    src: plantItemFour, 
    name: 'Medium Orchid', 
    price: '$30',
    ratings: "★★★",
    reviews: "#reviews"  
  }
];

const ProductBundle = () => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectItem = (product) => {
    const exists = selectedItems.some(item => item.name === product.name);
    if (exists) {
      setSelectedItems(selectedItems.filter(item => item.name !== product.name));
    } else {
      setSelectedItems([...selectedItems, product]);
    }
  };

  const handleRemoveItem = (name) => {
    setSelectedItems(selectedItems.filter(item => item.name !== name));
  };

  return (
    <div>
      <Helmet>
        <title>CICELY | Create Your Bundle</title>
        <meta name="description" content="Customize your purchase with our 'Create Your Own Bundle' feature. Select from a variety of products to build your perfect set and save 20%!" />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div className="container">
        <div className="product-display">
          <div className="product-image">
            <div id="selected-items" className="selected-items">
              {selectedItems.map((item, index) => (
                <div key={index} className="selected-item">
                  <img src={item.src} alt={item.name} />
                  <div className="close-icon" onClick={() => handleRemoveItem(item.name)}>
                    <IoMdClose size={24} />
                  </div>
                </div>
              ))}
            </div>
            <div className="image-other">
              {products.map(product => (
                <img
                  key={product.name}
                  src={product.src}
                  alt={product.name}
                  onClick={() => handleSelectItem(product)}
                />
              ))}
            </div>
          </div>
          <div className="product-details">
            <h1>Create Your Own Bundle</h1>
            <h2>$125</h2>
            <h3>Save 20% when you create your own bundle!</h3>
            <p>Select items from the thumbnail photos located on the bottom right to build your bundle.</p>
            <div className="rating">
              <span className="rating-star">★★★★★</span>
              <span># reviews</span>
            </div>
            <div className="button1">
              <button className="add-to-cart">ADD TO CART</button>
            </div>
            <div className="button2">
              <button className="buy-now">BUY NOW</button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="product-container">
            <h2>Similar Product</h2>
          </div>
          <div className="shop-grid">
            {products.map(product => (
              <div className="plant-item" key={product.name}>
                <img src={product.src} alt={product.name} width="100%" height="80%" />
                <div className="description">
                  <div className="info">
                    <span className="name">{product.name}</span>
                  </div>
                  <span className="price">{product.price}</span>
                </div>
                <div className="meta">
                  <span className="ratings">{product.ratings}</span>
                  <span className="reviews">{product.reviews}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBundle;
