import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence, motion} from 'framer-motion';
import Navbar from './components/navbar/Navbar';
import Body from './components/body/Body';
import Footer from './components/footer/Footer';
import Contact from './components/contact/Contact';
import Blog from './components/blog/Blog';
import Shop from './components/shop/Shop';
import Product from './components/product/Product';
import ProductBundle from './components/productBundle/ProductBundle';
import About from './components/about/About';
import Legal from './components/legal/Legal';
import Privacy from './components/privacy/Privacy';
import AllServices from './components/allServices/AllServices';
import PlantInstall from './components/services/PlantInstall';
import PlantDesign from './components/services/PlantDesign';
import PlantClass from './components/services/PlantClass';
import PlantHealth from './components/services/PlantHealth';
import PlantMaint from './components/services/PlantMaint';
import SeasonalPlanting from './components/services/SeasonalPlanting';
import AllCaseStudies from './components/allCaseStudies/AllCaseStudies';
import ResGardenPlantHealth from './components/caseStudies/ResGardenPlantHealth';
import ParkSeasonPlant from './components/caseStudies/ParkSeasonPlant';
import EduPlantClass from './components/caseStudies/EduPlantClass';
import CustomPlantDesign from './components/caseStudies/CustomPlantDesign';
import ResortPlantMaint from './components/caseStudies/ResortPlantMaint';
import OfficePlantInstall from './components/caseStudies/OfficePlatnInstall';
import PlantDirectory from './components/plantDirectory/PlantDirectory';
import IndividualPlant from './components/individualPlant/IndividualPlant';
import IndividualBlog from './components/individualBlog/IndividualBlog';

function App() {

  return (
    <>
      <Navbar />
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/individual-blog" element={<IndividualBlog />} />
            <Route path="/plant-directory" element={<PlantDirectory />} />
            <Route path="/individual-plant" element={<IndividualPlant />} />
            {/*
            <Route path="/shop" element={<Shop />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product-bundle" element={<ProductBundle />} />
            */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/plant-install" element={<PlantInstall />} />
            <Route path="/seasonal-planting" element={<SeasonalPlanting />} />
            <Route path="/plant-health" element={<PlantHealth />} />
            <Route path="/plant-class" element={<PlantClass />} />
            <Route path="/plant-maint" element={<PlantMaint />} />
            <Route path="/plant-design" element={<PlantDesign />} />
            <Route path="/all-services" element={<AllServices />} />
            <Route path="/res-garden-plant-health" element={<ResGardenPlantHealth />} />
            <Route path="/park-season-plant" element={<ParkSeasonPlant />} />
            <Route path="/edu-plant-class" element={<EduPlantClass />} />
            <Route path="/customer-plant-design" element={<CustomPlantDesign />} />
            <Route path="/resort-plant-maint" element={<ResortPlantMaint />} />
            <Route path="/office-plant-install" element={<OfficePlantInstall />} />
            <Route path="/all-case-studies" element={<AllCaseStudies />} />
          </Routes>
        </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
