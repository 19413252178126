import React, { useState, useEffect, useRef } from 'react';
import { FaSquareFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { FaToggleOn  } from "react-icons/fa";
import { IoMdMenu, IoMdClose, IoIosArrowDown  } from "react-icons/io";
import './Navbar.css';
import { navHoverImg } from '../../assist/index';
import { Link } from 'react-router-dom';
import MenuIcon from '../../variants/MenuIcon';
import CloseIcon from '../../variants/CloseIcon';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [subDropdown, setSubDropdown] = useState(null);
  const dropdownRef = useRef(null);
  /*
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [allServicesDropdown, setAllServicesDropdown] = useState(false);
  const [caseStudiesDropdown, setCaseStudiesDropdown] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [allCaseStudiesDropdown, setAllCaseStudiesDropdown] = useState(false);
  */
  /*
  const toggleServicesDropdown = () => {
    setServicesDropdown(!servicesDropdown);
    setCaseStudiesDropdown(false);  
    setGiftsDropdown(false);
  };

  const toggleCaseStudiesDropdown = () => {
    setCaseStudiesDropdown(!caseStudiesDropdown);
    setServicesDropdown(false); 
    setGiftsDropdown(false);
  };

  const toggleAllServicesDropdown = () => {
    setAllServicesDropdown(!allServicesDropdown);
    setCaseStudiesDropdown(false); 
  };

  const toggleAllCaseStudiesDropdown = () => {
    setAllCaseStudiesDropdown(!allCaseStudiesDropdown);
    setServicesDropdown(false); 
  };

   const toggleGiftsDropdown = () => { 
    setGiftsDropdown(!giftsDropdown);
    setServicesDropdown(false);
    setCaseStudiesDropdown(false);
  };
  */

   /*toggle hamburgermenu*/
   const toggleMenu = () => {
    setIsActive(!isActive);
  };
  

  const handleClick = (dropdownSetter) => {
    setActiveDropdown(prevDropdown => {
      if (prevDropdown === dropdownSetter) {
        return null;
      }
      return dropdownSetter;
    });
  };

  const handleSubClick = (subDropdownSetter) => {
    setSubDropdown(prevSubDropdown => {
      if (prevSubDropdown === subDropdownSetter) {
        return null;
      }
      return subDropdownSetter;
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
      setSubDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
 
  /*function for switch the light & dark mode*/
  useEffect(() => {
    const toggleTheme = () => {
      const body = document.body;
      const currentTheme = body.getAttribute('data-theme');
      const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
      body.setAttribute('data-theme', newTheme);
      localStorage.setItem('theme', newTheme);
    };

    window.toggleTheme = toggleTheme;

    const savedTheme = localStorage.getItem('theme') || 'light';
    document.body.setAttribute('data-theme', savedTheme);
  }, []);


  return (
    <div className="header-content">
      <nav className="navbar" ref={dropdownRef}>
        <div className="logo">
          <a href="/">CICELY</a>
          <button className="theme-toggle" onClick={() => window.toggleTheme()}>
            <FaToggleOn />
          </button>
        </div>
        <div className="navbar-right">
          <div className={`nav-upper ${isActive ? 'active' : ''}`}>
            <div className="nav-socials">
              <Link to="/" reloadDocument><FaSquareFacebook /></Link>
              <Link to="/" reloadDocument><FaInstagram /></Link>
              <Link to="/" reloadDocument><FaWhatsapp /></Link>
            </div>
          </div>
          <div className={`nav-lower ${isActive ? 'active' : ''}`}>
            <ul className={`nav-links ${isActive ? 'show' : 'hide'}`}>
              <div className="close-icon-item" onClick={toggleMenu}>
                  {isActive && <CloseIcon />}
              </div>
              <li><Link to="/plant-directory" reloadDocument>Plants Directory</Link></li>  
              <li><Link to="/blog" reloadDocument>Blog</Link></li>
              <li className={`nav-dropdown ${activeDropdown === 'caseStudies' ? 'active' : ''}`} onClick={() => handleClick('caseStudies')}>
                <Link>Case Studies<IoIosArrowDown className={`arrow-icon ${activeDropdown === 'caseStudies' ? 'rotate' : ''}`}/></Link>
                {activeDropdown === 'caseStudies' && (
                  <div className="dropdown-content">
                    <ul className={`nav-dropdown ${subDropdown === 'allCaseStudies' ? 'active' : ''}`} onClick={(e) => {
                      e.stopPropagation();
                      handleSubClick('allCaseStudies');
                    }}>
                      <li>
                        <Link>Case Studies<IoIosArrowDown className={`arrow-icon ${subDropdown === 'allCaseStudies' ? 'rotate' : ''}`}/></Link>
                        {subDropdown === 'allCaseStudies' && (
                          <ul className="sub-dropdown-content">
                            <li><Link to="/office-plant-install" reloadDocument>Office Plant Installation</Link></li>
                            <li><Link to="/resort-plant-maint" reloadDocument>Resort Plant Maintenance</Link></li>
                            <li><Link to="/customer-plant-design" reloadDocument>Custom Planter Design</Link></li>
                            <li><Link to="/edu-plant-class" reloadDocument>Education Plant Classes</Link></li>
                            <li><Link to="/park-season-plant" reloadDocument>Seasonal Planting in park</Link></li>
                            <li><Link to="/res-garden-plant-health" reloadDocument>Plant Health Diagnosis in Residence Garden</Link></li>
                          </ul>
                        )}
                      </li>
                    </ul>
                    <ul>
                      <li><Link to="/all-case-studies" reloadDocument>All Case Studies</Link></li>
                    </ul>
                  </div>
                )}
              </li>
              <li className={`nav-dropdown ${activeDropdown === 'services' ? 'active' : ''}`} onClick={() => handleClick('services')}>
                <Link>Services<IoIosArrowDown className={`arrow-icon ${activeDropdown === 'services' ? 'rotate' : ''}`} /></Link>
                {activeDropdown === 'services' && (
                  <div className="submenu-container">
                    <div className="hover-image">
                      <img src={navHoverImg} alt="Special Offer" />
                    </div>
                    <ul className="hover-links">
                      <li><Link to="/plant-install" reloadDocument>Plant Installation</Link></li>
                      <li><Link to="/plant-maint" reloadDocument>Plant Maintenance</Link></li>
                      <li><Link to="/plant-design" reloadDocument>Custom Planter Design</Link></li>
                      <li><Link to="/plant-class" reloadDocument>Plant Classes</Link></li>
                      <li><Link to="/seasonal-planting" reloadDocument>Seasonal Planting</Link></li>
                      <li><Link to="/plant-health" reloadDocument>Plant Health Diagnosis</Link></li>
                      <li><Link to="/all-services" reloadDocument>All Services</Link></li>
                    </ul>
                  </div>
                )}
              </li>
            </ul>
            <div className="toggleMenu" onClick={toggleMenu}>
              {!isActive && <MenuIcon />}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;