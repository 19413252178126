import React, { useState }  from 'react';
import './CaseStudies.css';
import { MdDescription, MdOutlineReportProblem, MdAutoFixHigh, MdCloudDone  } from "react-icons/md";
import {
    SeasonalPlantingOne,
    SeasonalPlantingTwo,
    SeasonalPlantingThree
} from '../../assist/index';
import { IoMdClose } from "react-icons/io";
import { Helmet } from 'react-helmet';
import pageTransition from '../../variants/pageTransition'

const parkCaseStudies = [
    {
        description: "Implemented seasonal planting strategies in urban parks, focusing on biodiversity and sustainability to enhance public enjoyment and ecological balance.",
        challenge: "Urban parks were experiencing reduced plant diversity and seasonal color, which diminished the aesthetic appeal and ecological function of these green spaces.",
        solution: "Developed and executed a seasonal planting plan that incorporated a variety of native and adaptive plant species. The plan was designed to provide year-round visual interest and support local wildlife, involving expert landscape architects and community volunteers in the planting process.",
        outcome: "The seasonal planting initiative successfully transformed the parks into vibrant, colorful, and biodiverse environments. Park visitors have increased, and surveys indicate higher satisfaction with the park's improved beauty and functionality. The initiative also fostered stronger community involvement and awareness of local ecology."
    }
];


const ParkSeasonPlant = () => {

    const [modal, setModal] = useState({ isOpen: false, imgSrc: '' });

    const openModal = (imgSrc) => {
        setModal({ isOpen: true, imgSrc });
    };

    const closeModal = () => {
        setModal({ isOpen: false, imgSrc: '' });
    };

    return (
        <section>
            <Helmet>
                <title>Seasonal Planting in Park</title>
                <meta name="description" content="Explore our seasonal planting strategies aimed at increasing biodiversity and sustainability in urban parks, enhancing both aesthetic appeal and ecological balance while fostering community involvement." />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <div>
                <div className="case-hero-image">
                    <div className="case-hero">
                        <h1>
                            Seasonal Planting in park
                        </h1>
                    </div>
                </div>
            </div>
            <div className="case-images">
                <img src={SeasonalPlantingOne} alt="Challenge" onClick={() => openModal(SeasonalPlantingOne)} />
                <img src={SeasonalPlantingTwo} alt="Solution" onClick={() => openModal(SeasonalPlantingTwo)} />
                <img src={SeasonalPlantingThree} alt="Outcome" onClick={() => openModal(SeasonalPlantingThree)} />
            </div>
            {modal.isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}><IoMdClose/></span>
                        <img src={modal.imgSrc} alt="Enlarged view" />
                    </div>
                </div>
            )}
            <div className="card-container">
                {parkCaseStudies.map((study, index) => (
                    <div key={index} className="card">
                        <div className="card-item">
                            <MdDescription className="icon" />
                            <h2>Description</h2>
                            <p>{study.description}</p>
                        </div>
                        <div className="card-item">
                            <MdOutlineReportProblem className="icon" />
                            <h2>Challenge</h2>
                            <p>{study.challenge}</p>
                        </div>
                        <div className="card-item">
                            <MdAutoFixHigh className="icon" />
                            <h2>Solution</h2>
                            <p>{study.solution}</p>
                        </div>
                        <div className="card-item">
                            <MdCloudDone className="icon" />
                            <h2>Outcome</h2>
                            <p>{study.outcome}</p>
                        </div>
                    </div>
                ))}
            </div>
            <hr/>
        </section>
    )
}

export default pageTransition(ParkSeasonPlant);