import React, { useState } from 'react';
import './CaseStudies.css';
import { MdDescription, MdOutlineReportProblem, MdAutoFixHigh, MdCloudDone  } from "react-icons/md";
import {
    resortPlantOne,
    resortPlantTwo,
    resortPlantThree
} from '../../assist/index';
import { IoMdClose } from "react-icons/io";
import { Helmet } from 'react-helmet';
import pageTransition from '../../variants/pageTransition';


const resortCaseStudies = [
    {
        description: "Provided comprehensive plant maintenance services for high-end resorts, ensuring lush, immaculate landscapes that enhance guest experience and environmental sustainability.",
        challenge: "Resorts faced challenges in maintaining their expansive and diverse landscapes, which are crucial for guest satisfaction but require constant, expert attention to remain pristine and healthy.",
        solution: "Deployed a team of specialized horticulturists to manage the resort landscapes, utilizing sustainable gardening practices and state-of-the-art horticultural technology. Implemented regular maintenance schedules, pest management plans, and seasonal updates to ensure year-round beauty and vitality.",
        outcome: "The ongoing maintenance program has kept resort landscapes in exceptional condition, significantly enhancing guest satisfaction and overall aesthetic appeal. The program has also reduced long-term landscaping costs and improved the resorts' environmental footprint."
    }
];


const ResortPlantMaint = () => {

    const [modal, setModal] = useState({ isOpen: false, imgSrc: '' });

    const openModal = (imgSrc) => {
        setModal({ isOpen: true, imgSrc });
    };

    const closeModal = () => {
        setModal({ isOpen: false, imgSrc: '' });
    };

    return (
        <section>
            <Helmet>
                <title>Resort Plant Maintenance</title>
                <meta name="description" content="Explore our specialized plant maintenance services for high-end resorts, ensuring lush landscapes that elevate guest experiences and promote environmental sustainability." />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <div>
                <div className="case-hero-image">
                    <div className="case-hero">
                        <h1>
                            Resort Plant Maintenance
                        </h1>
                    </div>
                </div>
            </div>
            <div className="case-images">
                <img src={resortPlantOne} alt="Challenge" onClick={() => openModal(resortPlantOne)} />
                <img src={resortPlantTwo} alt="Solution" onClick={() => openModal(resortPlantTwo)} />
                <img src={resortPlantThree} alt="Outcome" onClick={() => openModal(resortPlantThree)} />
            </div>
            {modal.isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}><IoMdClose/></span>
                        <img src={modal.imgSrc} alt="Enlarged view" />
                    </div>
                </div>
            )}
            <div className="card-container">
                {resortCaseStudies.map((study, index) => (
                    <div key={index} className="card">
                        <div className="card-item">
                            <MdDescription className="icon" />
                            <h2>Description</h2>
                            <p>{study.description}</p>
                        </div>
                        <div className="card-item">
                            <MdOutlineReportProblem className="icon" />
                            <h2>Challenge</h2>
                            <p>{study.challenge}</p>
                        </div>
                        <div className="card-item">
                            <MdAutoFixHigh className="icon" />
                            <h2>Solution</h2>
                            <p>{study.solution}</p>
                        </div>
                        <div className="card-item">
                            <MdCloudDone className="icon" />
                            <h2>Outcome</h2>
                            <p>{study.outcome}</p>
                        </div>
                    </div>
                ))}
            </div>
            <hr/>
        </section>
    )
}

export default pageTransition(ResortPlantMaint);