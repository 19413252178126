import React from 'react';
import { Helmet } from 'react-helmet';
import { individualPlant } from '../../assist/index'; 
import './IndividualPlant.css';
import pageTransition from '../../variants/pageTransition';

const IndividualPlant = () => {
  return (
    <div>
      <Helmet>
        <title>CICELY | Individual Plant</title>
        <meta name="description" content="Explore our wide range of plants and pots in the SWEET BAE shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div className="container">
        <div className="plant-layout">
          <div className="plant-image">
            <img src={individualPlant} alt="Bailey Compact Amur Maple" />
          </div>
          <div className="plant-details">
          <h1>Bonsai Tree</h1>

          <h2>Description</h2>
          <p>A beautiful and intricate miniature tree, the Bonsai Tree is a testament to the art of careful cultivation. Its delicate structure and unique forms make it a perfect addition to any home or garden, providing a touch of natural elegance.</p>

          <h2>Ornamental Features</h2>
          <p>The Bonsai Tree is prized for its aesthetic appeal, with carefully shaped branches and leaves that create a miniature version of a full-sized tree. The meticulous pruning and training result in a variety of stunning forms, from cascading to upright styles. </p>

          <h2>Landscape Attributes</h2>
          <p>This tree, though small, makes a big impact in any setting. Its fine texture and unique shape make it a standout piece in any landscape design. Bonsai Trees require regular maintenance, including pruning, watering, and sometimes repotting. </p>

          <h2>Planting & Growing</h2>
          <p>Bonsai Trees can vary in size but typically remain small, with heights ranging from a few centimeters to over a meter, depending on the species and style. They prefer a well-drained soil mix and require consistent watering, but not waterlogged conditions. </p>

          </div>
        </div>
      </div>
      <hr/>
      </div>
  );
}

export default pageTransition(IndividualPlant);
