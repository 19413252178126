import React, { useState } from 'react';
import './CaseStudies.css';
import { MdDescription, MdOutlineReportProblem, MdAutoFixHigh, MdCloudDone  } from "react-icons/md";
import {
    plantHealthOne,
    plantHealthTwo,
    plantHealthThree
} from '../../assist/index';
import { IoMdClose } from "react-icons/io";
import { Helmet } from 'react-helmet';
import pageTransition from '../../variants/pageTransition';


const caseStudies = [
    {
        description: "Provided expert plant health assessments and treatments to homeowners grappling with various plant diseases and pests, enhancing garden vitality and aesthetics.",
        challenge: "A significant number of residents were encountering recurring issues with plant health, including diseases and pests, but lacked the necessary expertise and tools to effectively manage these problems on their own.",
        solution: "Implemented a specialized service that offered comprehensive diagnostic and treatment services tailored to the specific needs of each garden. This included on-site assessments by trained horticulturists, who used advanced diagnostic techniques to identify the root causes of plant health issues.",
        outcome: "The intervention led to a marked improvement in the health and appearance of the residents' gardens. Homeowners reported not only healthier plants but also a greater enjoyment of their outdoor spaces, thanks to the sustainable practices and solutions provided."
    }
];

const ResGardenPlantHealth = () => {

    const [modal, setModal] = useState({ isOpen: false, imgSrc: '' });

    const openModal = (imgSrc) => {
        setModal({ isOpen: true, imgSrc });
    };

    const closeModal = () => {
        setModal({ isOpen: false, imgSrc: '' });
    };
    
return (
    <section>
        <Helmet>
            <title>Plant Health Diagnosis for Residential Gardens</title>
            <meta name="description" content="Discover our expert plant health assessment and treatment services for residential gardens, designed to combat diseases and pests, enhancing garden vitality and homeowner enjoyment." />
        </Helmet>
        <p className="discount">30% off spring sale</p>
        <div>
            <div className="case-hero-image">
                <div className="case-hero">
                    <h1>
                        Plant Health Diagnosis for Residential Gardens
                    </h1>
                </div>
            </div>
        </div>
        <div className="case-images">
                <img src={plantHealthOne} alt="Challenge" onClick={() => openModal(plantHealthOne)} />
                <img src={plantHealthTwo} alt="Solution" onClick={() => openModal(plantHealthTwo)} />
                <img src={plantHealthThree} alt="Outcome" onClick={() => openModal(plantHealthThree)} />
        </div>
        {modal.isOpen && (
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={closeModal}><IoMdClose/></span>
                    <img src={modal.imgSrc} alt="Enlarged view" />
                </div>
            </div>
        )}
        <div className="card-container">
            {caseStudies.map((study, index) => (
                <div key={index} className="card">
                    <div className="card-item">
                        <MdDescription className="icon" />
                        <h2>Description</h2>
                        <p>{study.description}</p>
                    </div>
                    <div className="card-item">
                        <MdOutlineReportProblem className="icon" />
                        <h2>Challenge</h2>
                        <p>{study.challenge}</p>
                    </div>
                    <div className="card-item">
                        <MdAutoFixHigh className="icon" />
                        <h2>Solution</h2>
                        <p>{study.solution}</p>
                    </div>
                    <div className="card-item">
                        <MdCloudDone className="icon" />
                        <h2>Outcome</h2>
                        <p>{study.outcome}</p>
                    </div>
                </div>
            ))}
        </div>
        <hr/>
    </section>
)
}

export default pageTransition(ResGardenPlantHealth);