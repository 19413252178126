// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plant-layout {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

.plant-image {
    flex: 1 1;
    margin-top: 1rem;
}

.plant-image img {
    width: 100%;
    height: 80vh;
}

.plant-details {
    flex: 1 1;
    margin-left: 20px;
}

.plant-details h1 {
    font-size: 24px;
    margin-bottom: 10px;
    background-color: var(--accent-color);
    color: var(--text-color);
    border-radius: 5rem;
}

.plant-details p {
    margin: 5px 0;
}

.plant-details h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: var(--accent-color);
}

@media (max-width: 1200px) {
    .plant-layout {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .plant-image {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .plant-image img {
        width: 90vw;
        height: auto;
        object-fit: cover;
    }

    .plant-details {
        max-width: 100%;
        margin-left: 0;
        padding: 15px;
        margin-top: 10px;
    }

    .plant-details h1 {
        font-size: 20px;
    }

    .plant-details h2 {
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/individualPlant/IndividualPlant.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,SAAO;IACP,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,qCAAqC;IACrC,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,aAAa;IACjB;;IAEA;QACI,eAAe;QACf,mBAAmB;IACvB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,iBAAiB;IACrB;;IAEA;QACI,eAAe;QACf,cAAc;QACd,aAAa;QACb,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".plant-layout {\n    display: flex;\n    justify-content: space-between;\n    padding: 30px;\n}\n\n.plant-image {\n    flex: 1;\n    margin-top: 1rem;\n}\n\n.plant-image img {\n    width: 100%;\n    height: 80vh;\n}\n\n.plant-details {\n    flex: 1;\n    margin-left: 20px;\n}\n\n.plant-details h1 {\n    font-size: 24px;\n    margin-bottom: 10px;\n    background-color: var(--accent-color);\n    color: var(--text-color);\n    border-radius: 5rem;\n}\n\n.plant-details p {\n    margin: 5px 0;\n}\n\n.plant-details h2 {\n    font-size: 20px;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    text-align: center;\n    color: var(--accent-color);\n}\n\n@media (max-width: 1200px) {\n    .plant-layout {\n        flex-direction: column;\n        align-items: center;\n        padding: 10px;\n    }\n\n    .plant-image {\n        max-width: 100%;\n        margin-bottom: 10px;\n    }\n\n    .plant-image img {\n        width: 90vw;\n        height: auto;\n        object-fit: cover;\n    }\n\n    .plant-details {\n        max-width: 100%;\n        margin-left: 0;\n        padding: 15px;\n        margin-top: 10px;\n    }\n\n    .plant-details h1 {\n        font-size: 20px;\n    }\n\n    .plant-details h2 {\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
