import React from 'react';
import './Blog.css';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../assist/index';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pageTransition from '../../variants/pageTransition';

const blogPosts = [
  {
    img: blogImgOne,
    alt: "Wellness and Your Indoor Air Quality",
    date: "May 17, 2021",
    title: "Wellness and Your Indoor Air Quality",
  },
  {
    img: blogImgTwo,
    alt: "Simple Spring Projects",
    date: "April 05, 2021",
    title: "Simple Spring Projects",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z",
  },
  {
    img: blogImgThree,
    alt: "Pet-Friendly Plants",
    date: "February 2, 2021",
    title: "Pet-Friendly Plants",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z Again",
  },
  {
    img: blogImgThree,
    alt: "Easy as Z to Z",
    date: "February 28, 2021",
    title: "Easy as Z to Z Revisited",
  }
];

const Blog = () => {
  return (
    <div>
      <Helmet>
        <title>CICELY | Blog</title>
        <meta name="description" content="Explore engaging ideas and practical tips on our blog." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div class="blog-hero">
            <div class="blog-hero-content">
                <h1>
                    Blog
                </h1>
            </div>
        </div>
        <div className="container">
          <div className="blog-content">
            <h2>The Potted Post</h2>
            <div className="blog-pottedposts">
              {blogPosts.map((post, index) => (
                <div key={index} className="pottedpost">
                  <img src={post.img} alt={post.alt} />
                  <div className="potted-content-overlay">
                    <p>{post.date}</p>
                    <h2>{post.title}</h2>
                    <Link to="/individual-blog" reloadDocument><button>Read More</button></Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pageTransition(Blog);
