import React from 'react';
import './Shop.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { plantItemOne, 
        plantItemTwo, 
        plantItemThree, 
        plantItemFour, 
        plantItemFive, 
        plantItemSix, 
        plantItemSeven, 
        plantItemEight, 
        plantItemNine } from '../../assist/index';

const plantItems = [
    {
      img: plantItemOne,
      name: "Assorted Small Succulents",
      price: "$10",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemTwo,
      name: "Small Aloe Vera",
      price: "$10",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemThree,
      name: "Medium Bonsai",
      price: "$25",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemFour,
      name: "Medium Orchid",
      price: "$30",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemFive,
      name: "Large White Peace Lily",
      price: "$40",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemSix,
      name: "Large Monstera (Swiss Cheese) Plant",
      price: "$75",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemSeven,
      name: "Large Snake Plant",
      price: "$75",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemEight,
      name: "Large Cactus",
      price: "$85",
      ratings: "★★★★★",
      reviews: "#reviews",
    },
    {
      img: plantItemNine,
      name: "Office Bundle",
      price: "$225",
      ratings: "★★★★★",
      reviews: "#reviews",
    }
  ];

  const Shop = () => {
    
    return (
        <div>
            <Helmet>
                <title>CICELY | Shop</title>
                <meta name="description" content="Explore our wide range of plants and pots in the Cicely shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <div>
                <div className="shop-hero-image">
                    <div className="shop-hero">
                        <div className="shop-hero-content">
                            <h1>
                                Large or small. <br />
                                We've got <br />
                                it all.
                            </h1>
                            <button><Link to="/shop" reloadDocument>Shop all</Link></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gift">
                <div className="container">
                    <div className="shop-header-content">
                        <h2>All Gifts</h2>
                        <nav className="gift-nav">
                            <Link to="/product" reloadDocument>All Plants</Link>
                            <Link to="/blog"    reloadDocument>All Pots</Link>
                            <Link to="/product-bundle"   reloadDocument>All Gifts</Link>
                            <div className="view-links">
                                <Link to="#view1"   reloadDocument>View 1</Link>
                                <Link to="#view2"   reloadDocument>2</Link>
                                <Link to="#view3"   reloadDocument>3</Link>
                                <Link to="#view4"   reloadDocument>4</Link>
                                <Link to="#col5"    reloadDocument>5</Link>
                                <Link to="#filters" reloadDocument>+Filters</Link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="shop-grid">
                    {plantItems.map((item, index) => (
                        <div className="plant-item" key={index}>
                            <img src={item.img} alt={item.name} width="100%" height="80%" />
                            <div className="description">
                                <div className="info">
                                    <span className="name"><Link to="/shop" reloadDocument>{item.name}</Link></span>
                                </div>
                                <span className="price">{item.price}</span>
                            </div>
                            <div className="meta">
                                <span className="ratings">{item.ratings}</span>
                                <span className="reviews">{item.reviews}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <div className="shop-bottom-hero">
                    <div className="shop-bottom-hero-content">
                        <h2>
                            Not sure where <br />
                            to start? We do.<br />
                        </h2>
                        <p>We're here to help.</p>
                        <button><Link to="/contact" reloadDocument>Talk to us</Link></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shop;