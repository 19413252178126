import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MdLabelImportant } from "react-icons/md";
import {
    plantHealthSlideOne,
    plantHealthSlideTwo
} from '../../assist/index';
import './Services.css';
import pageTransition from '../../variants/pageTransition';

const plantHealth = {
    overview: "Revitalize your garden with our expert Plant Health services. We focus on diagnosing and treating plant health issues using sustainable methods to ensure your garden remains lush and disease-free. Our team of experts is equipped to handle a variety of plant health concerns, from nutrient deficiencies to pest infestations, making our services essential for both amateur gardeners and professional landscapers.",
    capabilities: [
      "In-depth plant diagnostics to identify and address diseases, pests, and environmental stresses.",
      "Nutrient management plans tailored to the specific needs of your garden's soil and plant types.",
      "Eco-friendly pest control solutions that are safe for your plants, your family, and the environment.",
      "Root care and aeration techniques to promote healthy plant growth.",
      "Preventative care strategies to maintain plant health and prevent future problems."
    ],
    recognition: "Recognized for our commitment to plant wellness and sustainable practices, we've been featured in 'Healthy Gardens' magazine and received the Plant Protector Award from the National Gardening Association.",
    detailedServices: [
      {
        title: "Disease Prevention and Treatment",
        description: "Proactive and reactive care to protect your plants from diseases, using the latest in eco-friendly treatments."
      },
      {
        title: "Pest Management Programs",
        description: "Customized pest control programs designed to effectively manage and eradicate pests while keeping your garden safe and healthy."
      },
      {
        title: "Soil Health Enhancement",
        description: "Services to improve soil fertility and structure, ensuring your plants receive the nutrients they need to thrive."
      }
    ],
  };
  
const PlantHealth = () => {
    return (
        <div>
            <Helmet>
                <title>CICELY | Plant Health Services</title>
                <meta name="description" content="Explore our comprehensive Plant Health services at CICELY, designed to tackle a range of garden health issues with sustainable solutions." />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <div>
                <div className="service-hero-image">
                    <div className="service-hero">
                        <h1>
                            Plant Health
                        </h1>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="service-details-layout">
                    <div className="service-text">
                    <p>{plantHealth.overview}</p>
                    <div>
                        <h2>Our Capabilities</h2>
                        <ul>
                        {plantHealth.capabilities.map((capability, index) => (
                            <li key={index}>{capability}</li>
                        ))}
                        </ul>
                    </div>
                    <div>
                        <h2>Recognition</h2>
                        <p>{plantHealth.recognition}</p>
                    </div>
                    <div>
                        <h2>What We Offer</h2>
                        {plantHealth.detailedServices.map((detail, index) => (
                        <article key={index}>
                            <h3><MdLabelImportant className='label'/>{detail.title}</h3>
                            <p>{detail.description}</p>
                        </article>
                        ))}
                    </div>
                    </div>
                    <div className="service-images">
                        <img src={plantHealthSlideOne} alt="Pest Management Programs" />
                        <img src={plantHealthSlideTwo} alt="Soil Health Enhancement" />
                    </div>
                </div>
            </div>
            <div>
                <div className="service-bottom-hero">
                    <div className="service-bottom-hero-content">
                        <h2>
                            Restore and Protect 
                            Your Garden's Health!
                        </h2>
                        <h3>Contact us today!</h3>
                        <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default pageTransition(PlantHealth);